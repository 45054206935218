@import "upstairs/static_src/styles/_variables";
@import "upstairs/static_src/styles/_placeholders";
@import "upstairs/static_src/styles/_mixins";

.home-listings__subtitle {
  font-weight: 700;
  text-transform: uppercase;
  font-size: .8rem;
  color: $brand;
}

.home-listings__title {
  font-size: 1.5rem;
  // color: $brand2;
  letter-spacing: -1px;
  line-height: 1;

  @include mediaQuery($min, $lg) {
    font-size: 2.5vw;
  }
}

.home-listings__link {
  font-size: .9rem;
  color: $brand;

  &:hover {
    color: $brand2;
  }
}
